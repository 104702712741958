var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "context-title",
        {
          attrs: {
            title: "Edit User",
            passedActions: _vm.primaryTitleBarActions,
          },
        },
        [
          _c("template", { slot: "secondary" }, [
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { "margin-left": "auto" } },
              [
                _c(
                  "text-button",
                  {
                    staticClass: "block-xs--sm-left",
                    staticStyle: { "margin-right": "20px" },
                    attrs: { type: "delete" },
                    nativeOn: {
                      click: function ($event) {
                        return (() =>
                          (_vm.deleteConfirmationModal = true)).apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("Delete User")]
                ),
                _vm.canSendInvitation
                  ? _c(
                      "text-button",
                      {
                        staticClass: "block-xs--sm-left",
                        staticStyle: { "margin-right": "20px" },
                        attrs: { type: "green" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onSendInvitation.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Resend Invitation")]
                    )
                  : _vm._e(),
                _vm.hasPreviousItem()
                  ? _c(
                      "ActionButton",
                      {
                        attrs: {
                          type: "faded",
                          noIcon: true,
                          action: _vm.gotoPreviousItem,
                        },
                      },
                      [_vm._v("View Previous")]
                    )
                  : _vm._e(),
                _c(
                  "ActionButton",
                  { attrs: { type: "faded", noIcon: true, action: _vm.leave } },
                  [_vm._v("Close")]
                ),
                _vm.hasNextItem()
                  ? _c(
                      "ActionButton",
                      {
                        attrs: {
                          type: "faded",
                          noIcon: true,
                          action: _vm.gotoNextItem,
                        },
                      },
                      [_vm._v("View Next")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "v-container",
        { staticClass: "fill-height pa-3 pl-5", attrs: { fluid: "" } },
        [
          _vm.userCopy
            ? _c(
                "form",
                { staticClass: "user-form" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "label required align",
                          attrs: { cols: "12", md: "2" },
                        },
                        [_vm._v("Name")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "form-input",
                          attrs: { cols: "12", sm: "6", md: "3" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userCopy.name,
                                expression: "userCopy.name",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.userCopy.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.userCopy,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "label required align",
                          attrs: { cols: "12", md: "2" },
                        },
                        [_vm._v("Email")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "form-input",
                          attrs: { cols: "12", sm: "6", md: "3" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userCopy.email,
                                expression: "userCopy.email",
                              },
                            ],
                            attrs: { type: "text", disabled: "" },
                            domProps: { value: _vm.userCopy.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.userCopy,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "form-border-bottom" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "label align",
                          attrs: { cols: "12", md: "2" },
                        },
                        [_vm._v("Phone Number")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "form-input",
                          attrs: { cols: "12", sm: "6", md: "3" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userCopy.phone,
                                expression: "userCopy.phone",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.userCopy.phone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.userCopy,
                                  "phone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "label required align",
                          attrs: { cols: "12", md: "2" },
                        },
                        [_vm._v("Temporary Partner Code")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "form-input",
                          attrs: { cols: "12", sm: "6", md: "3" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userCopy.partnerShortCode,
                                expression: "userCopy.partnerShortCode",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.userCopy.partnerShortCode },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.userCopy,
                                  "partnerShortCode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "form-border-bottom" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "label align",
                          attrs: { cols: "12", md: "2" },
                        },
                        [_vm._v("Delivery Partner?")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: { color: "#93BD20" },
                            model: {
                              value: _vm.userCopy.deliveryPartner,
                              callback: function ($$v) {
                                _vm.$set(_vm.userCopy, "deliveryPartner", $$v)
                              },
                              expression: "userCopy.deliveryPartner",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.userCopy.deliveryPartner
                    ? _c(
                        "v-row",
                        { staticClass: "form-border-bottom" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "label align",
                              attrs: { cols: "12", md: "2" },
                            },
                            [_vm._v("Stores")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "store-container",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _vm._l(
                                _vm.userCopy.storeAccess,
                                function (store) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: store.id,
                                      attrs: { align: "center" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "store",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              `${store.storeName} (${store.airportIdent})`
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass: "row--edit",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return (() => {
                                              _vm.storeAccessToEdit = store
                                            }).apply(null, arguments)
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "row--delete",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.removeStore(store)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "cursor pl-0 pb-3",
                                  attrs: { align: "center" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() => {
                                        _vm.addStoreModal = true
                                      }).apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "store-add-logo",
                                    attrs: { sm1: "" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "store",
                                      attrs: { sm11: "" },
                                    },
                                    [_vm._v("Add Store")]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.navigationConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Unsaved Changes",
                    onExit: () => (_vm.navigationConfirmationModal = false),
                    actions: _vm.navigationConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "You have unsaved changes, would you like to save these changes?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Confirm Delete",
                    onExit: () => (_vm.deleteConfirmationModal = false),
                    actions: _vm.deleteConfirmationActions,
                  },
                },
                [_vm._v("Are you sure you want to delete this user?")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.storeAccessToEdit
        ? _c("EditStoreModal", {
            attrs: { store: _vm.storeAccessToEdit },
            on: { close: _vm.onEditStoreClose, save: _vm.onEditStoreSave },
          })
        : _vm._e(),
      _vm.addStoreModal
        ? _c("AddStoresModal", {
            on: { close: _vm.onAddStoreClose, save: _vm.onAddStoreSave },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }