<template>
  <div>
    <context-title title="Edit User" :passedActions="primaryTitleBarActions">
      <template slot="secondary">
        <div class="d-flex" style="margin-left: auto;">
          <text-button
            class="block-xs--sm-left"
            style="margin-right: 20px;"
            type="delete"
            @click.native="() => deleteConfirmationModal = true"
          >Delete User</text-button>
          <text-button
            v-if="canSendInvitation"
            class="block-xs--sm-left"
            style="margin-right: 20px;"
            type="green"
            @click.native="onSendInvitation"
          >Resend Invitation</text-button>
          <ActionButton
            v-if="hasPreviousItem()"
            type="faded"
            :noIcon="true"
            :action="gotoPreviousItem"
          >View Previous</ActionButton>
          <ActionButton type="faded" :noIcon="true" :action="leave">Close</ActionButton>

          <ActionButton
            v-if="hasNextItem()"
            type="faded"
            :noIcon="true"
            :action="gotoNextItem"
          >View Next</ActionButton>
        </div>
      </template>
    </context-title>
    <v-container class="fill-height pa-3 pl-5" fluid>
      <form class="user-form" v-if="userCopy">
        <v-row  >
          <v-col cols="12" md="2" class="label required align">Name</v-col>
          <v-col cols="12" sm="6" md="3" class="form-input">
            <input type="text" v-model="userCopy.name" />
          </v-col>
        </v-row>
        <v-row  >
          <v-col cols="12" md="2" class="label required align">Email</v-col>
          <v-col cols="12" sm="6" md="3" class="form-input">
            <input type="text" v-model="userCopy.email" disabled />
          </v-col>
        </v-row>
        <v-row   class="form-border-bottom">
          <v-col cols="12" md="2" class="label align">Phone Number</v-col>
          <v-col cols="12" sm="6" md="3" class="form-input">
            <input type="text" v-model="userCopy.phone" />
          </v-col>
        </v-row>
        <v-row  >
          <v-col cols="12" md="2" class="label required align"
            >Temporary Partner Code</v-col
          >
          <v-col cols="12" sm="6" md="3" class="form-input">
            <input type="text" v-model="userCopy.partnerShortCode" />
          </v-col>
        </v-row>
        <v-row   class="form-border-bottom">
          <v-col cols="12" md="2" class="label align"
            >Delivery Partner?</v-col
          >
          <v-col cols="12" sm="6" md="3">
            <v-checkbox
              color="#93BD20"
              v-model="userCopy.deliveryPartner"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row   class="form-border-bottom" v-if="!userCopy.deliveryPartner">
          <v-col cols="12" md="2" class="label align">Stores</v-col>
          <v-col cols="12" md="6" class="store-container">
            <v-row align="center"  v-for="store in userCopy.storeAccess" :key="store.id">
              <v-col cols="4" class="store">{{`${store.storeName} (${store.airportIdent})`}}</v-col>
              <div class="row--edit" @click.stop="() => { storeAccessToEdit = store}"></div>
              <div class="row--delete" @click.stop="removeStore(store)"></div>
            </v-row>
            <v-row
              align="center"
              class="cursor pl-0 pb-3"
              @click.stop="() => { addStoreModal = true }"
            >
              <div sm1 class="store-add-logo" />
              <div sm11 class="store">Add Store</div>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </v-container>
    <EditingModal v-if="navigationConfirmationModal" size="sm">
      <AlertDialog
        title="Unsaved Changes"
        :onExit="() => navigationConfirmationModal = false"
        :actions="navigationConfirmationActions"
      >You have unsaved changes, would you like to save these changes?</AlertDialog>
    </EditingModal>
    <EditingModal v-if="deleteConfirmationModal" size="sm">
      <AlertDialog
        title="Confirm Delete"
        :onExit="() => deleteConfirmationModal = false"
        :actions="deleteConfirmationActions"
      >Are you sure you want to delete this user?</AlertDialog>
    </EditingModal>
    <EditStoreModal
      v-if="storeAccessToEdit"
      :store="storeAccessToEdit"
      @close="onEditStoreClose"
      @save="onEditStoreSave"
    />
    <AddStoresModal v-if="addStoreModal" @close="onAddStoreClose" @save="onAddStoreSave" />
  </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import ActionButton from "components/action_button";
import TextButton from "components/text_button.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import { mapGetters, mapActions } from "vuex";
import clone from "clone";

import EditStoreModal from "components/users/edit_store_modal";
import AddStoresModal from "components/users/add_stores_modal";

export default {
    name: "UserEdit",
    data() {
        return {
            deleteConfirmationModal: false,
            deleteConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: () => (this.deleteConfirmationModal = false)
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: this.deleteItem
                }
            ],
            pendingRoute: undefined,
            navigationConfirmationModal: false,
            navigationConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: this.confirmNavigation
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: () => {
                        this.save();
                    }
                }
            ],
            editRoute: "user-edit",
            leaveRoute: "users",
            userCopy: undefined,
            storeAccessToEdit: undefined,
            addStoreModal: false,
            storesDirty: false
        };
    },
    components: {
        ContextTitle,
        ActionButton,
        TextButton,
        AlertDialog,
        EditingModal,
        EditStoreModal,
        AddStoresModal
    },
    computed: {
        primaryTitleBarActions() {
            if (!this.dirty || !this.valid) return [];

            const items = [];

            const previous = {
                type: "proceed-green",
                display: "Save & View Previous",
                run: () => {
                    this.save().then(() => this.gotoPreviousItem());
                }
            };
            const saveClose = {
                type: "proceed-green",
                display: "Save & Close",
                run: () => {
                    this.save().then(() => this.leave());
                }
            };

            const saveNext = {
                type: "next-green",
                display: "Save & View Next",
                run: () => {
                    this.save().then(() => {
                        this.gotoNextItem();
                    });
                }
            };

            items.push(saveClose);

            if (this.hasNextItem(this.productId)) {
                items.push(saveNext);
            }

            return items;
        },
        user() {
            return this.users[this.userIndex];
        },
        userIndex() {
            if (!this.users.length) return false;
            return this.users.findIndex(el => el.id == this.$route.params.userID);
        },
        dirty() {
            if (this.userCopy == undefined || this.user == undefined) return false;
            return (
                this.user.name != this.userCopy.name ||
        this.user.phone != this.userCopy.phone ||
        this.user.partnerShortCode != this.userCopy.partnerShortCode ||
        this.user.deliveryPartner != this.userCopy.deliveryPartner ||
        this.storesDirty
            );
        },
        valid() {
            if (this.userCopy == undefined || this.user == undefined) return false;

            return (
                this.userCopy.name.length > 0 &&
        this.userCopy.partnerShortCode.length > 0
            );
        },
        canSendInvitation() {
            if (this.userCopy == undefined) return false;
            if (this.userCopy.invitationCode == "EXPIRED") return false;
            return (
                this.userCopy.invitationCode != null &&
        this.userCopy.invitationCode != undefined
            );
        },
        ...mapGetters("UserStore", ["users"])
    },
    methods: {
        deleteItem() {
            this.deleteConfirmationModal = false;
        },
        gotoNextItem() {
            if (!this.hasNextItem) return;
            let nextUserID = this.users[this.userIndex + 1].id;
            this.guardRouteAway({
                name: this.editRoute,
                params: {
                    userID: nextUserID
                }
            });
        },
        gotoPreviousItem() {
            if (!this.hasPreviousItem) return;
            let nextUserID = this.users[this.userIndex - 1].id;
            this.guardRouteAway({
                name: this.editRoute,
                params: {
                    userID: nextUserID
                }
            });
        },
        leave() {
            this.guardRouteAway({
                name: this.leaveRoute
            });
        },
        hasNextItem() {
            if (this.userIndex == -1 || this.userIndex + 1 >= this.users.length)
                return false;
            return true;
        },
        hasPreviousItem() {
            if (this.userIndex == -1 || this.userIndex == 0) return false;
            return true;
        },
        guardRouteAway(route) {
            if (!this.dirty) {
                this.$router.push(route);
                return;
            }

            this.pendingRoute = route;
            this.navigationConfirmationModal = true;
        },
        confirmNavigation() {
            this.navigationConfirmationModal = false;
            this.$router.push(this.pendingRoute);
            this.pendingRoute = undefined;
        },
        save() {
            return this.updateUser(this.userCopy).then(() => {
                this.userCopy = undefined;
                return Promise.resolve(true);
            });
        },
        init() {
            if (this.users.length == 0) this.getUsers();
            this.userCopy = clone(this.user);
        },
        onEditStoreClose() {
            this.storeAccessToEdit = undefined;
        },
        onEditStoreSave(newStore) {
            this.storeAccessToEdit = undefined;
            let index = this.userCopy.storeAccess.findIndex(
                el => el.storeWaypointID == newStore.storeWaypointID
            );
            if (index == -1) return;
            this.userCopy.storeAccess.splice(index, 1);
            this.userCopy.storeAccess.push(newStore);
            this.storesDirty = true;
        },
        onAddStoreClose() {
            this.addStoreModal = false;
        },
        onAddStoreSave({ stores, permissions }) {
            this.addStoreModal = false;
            for (let store of stores) {
                this.userCopy.storeAccess.push({
                    ...store,
                    permissions
                });
            }
            this.storesDirty = true;
        },
        onSendInvitation() {
            this.sendUserInvitation(this.user).then(() => {
                this.toast('Invitation Sent', 'success')
            }).catch(() => {
                this.toast('Failed to Send Invitation', 'error')

            })
        },
        removeStore(store) {
            let index = this.userCopy.storeAccess.findIndex(el => el.airportIdent === store.airportIdent)

            if (index == -1) return;

            var access = clone(this.userCopy.storeAccess)
            access.splice(index, 1)
            this.userCopy.storeAccess = access
            this.storesDirty = true
        },
        ...mapActions("UserStore", ["getUsers", "updateUser", "sendUserInvitation"])
    },
    mounted() {
        this.init();
    },
    watch: {
        user(newValue, oldValue) {
            this.init();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";
@import "~scss/variables";

.cursor {
  cursor: pointer;
}

.cursor .store {
  margin-top: 2px;
}
.store-add-logo {
  @include background-image("", "sublocation_add.svg", "../../../img/");
  margin-left: 10px;
  margin-right: 10px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}

.user-form {
  width: 100%;

  .form-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
  }
  .label {
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
  }
  input {
    width: 100%;
  }

  .required:after {
    color: #ff0000;
    content: " *";
    display: inline;
  }
}

.row {
  &--delete {
    @include background-image("", "delete.svg", "../../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 15px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.store {
  color: $brand_color;
}
</style>
